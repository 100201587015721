import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// const publicPagetypes = {
//   publicWithoutAuth: 1,
//   public: 2,
// };

const routes = [
  {
    path: "/",
    name: "Index",
    component: () => import("@/views/Index.vue"),
  },
  {
    path: "/review",
    name: "Review",
    component: () => import("@/views/Review.vue"),
  },
  {
    path: "/newreview",
    name: "NewReview",
    component: () => import("@/views/NewReview.vue"),
  },
  {
    path: "/thankyou",
    name: "Thankyou",
    component: () => import("@/views/Thankyou.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("../views/ForgotPassword.vue"),
    // meta: {publicPageType: publicPagetypes.publicWithoutAuth},
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import("@/views/NotFound.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
