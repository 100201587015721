<template>
  <v-app>
    <v-main class="main-app">
      <router-view />
    </v-main>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script async src="https://www.googletagmanager.com/gtag/js?id=G-EHWSFT0H6Z"></script>
<script>
import "@/scss/global.scss";
import "@/scss/shopify.css";
import "@/scss/rotterdam.scss";

export default {
  name: "App",
  data: () => ({
    overlay: false,
  }),
  mounted() {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, "", window.location.href);
      alert("Gebruik de 'Vorige' of 'Volgende' knop om te navigeren in de woningscan.");
    };
    // hide the overlay when everything has loaded
    setTimeout(() => {
      this.overlay = false;
    }, 500);

    // implement google tag
    window.dataLayer = window.dataLayer || [];
    this.gTag("js", new Date());
    this.gTag("config", "G-EHWSFT0H6Z");
  },
  methods: {
    gTag() {
      dataLayer.push(arguments);
    },
  },
};
</script>
