import axios from "./axios";
// import {Process as process} from "node/process";

function handleResponse(response) {

  const { data, status } = response;
  console.log("API called");
  console.log('handle data',data);
  if (status !== 200 && status !== 201) {
    if (status === 401) {
      console.log("Error");
    }
    return Promise.reject(response);
  }
  return data;
}
 

function getHouseTypes() {
  return axios.get("/api/house-types/").then(handleResponse);
}

function getUser(uid) {
  return axios.get(`/api/user-quote/${uid}/`).then(handleResponse);
}
function getHouseItems() {
  return axios.get("/api/items/").then(handleResponse);
}

function createUser(reqData) {
  return axios.post("/api/user-quote/", reqData).then(handleResponse);
}
function updateUser(uid, reqData) {
  return axios.patch(`/api/user-quote/${uid}/`, reqData
  ).then(handleResponse);
}
function sendUserQuote(uid, reqData) {
  const { domain } = reqData;
  delete reqData.domain;
  return axios
    .get(`/api/send-user-quote/${uid}/?domain=${domain}`)
    .then(handleResponse);
}

function getItemUserQuestion(reqData) {
  return axios
    .get(`/api/item-quote-questions/${reqData}/`)
    .then(handleResponse);
}

function getRecommendedItem(reqData, uid) {
  return axios
    .post(`/api/item-recommendations/${uid}/`, reqData)
    .then(handleResponse);
}

function sendItemUserValues(reqData) {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return axios
    .post(`/api/item-question-answers/`, reqData, config)
    .then(handleResponse);
}
function sendItemUserSubsidy(reqData, uid) {
  return axios
    .post(`/api/user-eligibility-subsidy/${uid}/`, reqData)
    .then(handleResponse);
}
function getItemUserSubsidy(uid) {
  return axios
    .get(`/api/user-eligibility-subsidy/${uid}/`)
    .then(handleResponse);
}

function getConstructionYear(url) {
  const config = {
    headers: {
      "X-Api-Key": "l7610cc2c1b3a247e7844fbf787cfdf8fd",
      accept: "application/hal+json",
      "Accept-Crs": "epsg:28992",
    },
  };
  return axios.get(`${url}`, config).then(handleResponse);
}
function getLivingSpace(url) {
  const config = {
    headers: {
      "X-Api-Key": "l7610cc2c1b3a247e7844fbf787cfdf8fd",
      accept: "application/hal+json",
      "Accept-Crs": "epsg:28992",
    },
  };
  return axios.get(`${url}`, config).then(handleResponse);
}

function getStreetInfo(url) {
  const config = {
    headers: {
      accept: "application/hal+json",
      "X-Api-Key": "l7610cc2c1b3a247e7844fbf787cfdf8fd",
    },
  };
  return axios.get(`${url}`, config).then(handleResponse);
}
function getCityQuestion(reqBody) {
  return axios
    .get(`/api/city/`, { params: reqBody })
    .then(handleResponse);
}
function getDomainInfo(queryParams) {
  return axios
    .get(`/api/lead-partner/`, {
      params: queryParams
      // params: {domain: 'https://woningscan.woonwijzerwinkel.nl'}
    })
    .then(handleResponse);
}
function getLogoScanDomainInfo(queryParams) {
  return axios
    .get(`/api/logo-scan-config/`, {
      params: queryParams
      // params: {domain: 'https://woningscan.woonwijzerwinkel.nl'}
    })
    .then(handleResponse);
}

function loginUser(payload) {
  return axios
    .post(`/api/logo-scan-login/`, payload, {
      params: {domain: window.location.origin}
    })
    .then((res) => {
      res.status === 200
        ? (axios.defaults.headers.common = {
            Authorization: `Bearer ${res.data.access}`,
            // Authorization: `Token ${res.data.key}`,

          })
        : "";
      return res;
    })
    .then(handleResponse);
}

function forgotPassword(payload) {
  return axios.post(`/api/auth/password/reset/`, payload).then(handleResponse);
}

function createReview(payload) {
  // const token = payload.token;
  // Vue.delete(payload, "token");
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const token = payload.get("token");
  payload.delete("token"); 
  console.log(payload.get('images'));
  return axios
    .post(`/api/reviews/?token=${token}`, payload, config)
    .then(handleResponse);
}

// async function sendPermissionUpdated(response) {
//   const params = {
//     search: localStorage.getItem("email"),
//   };
//   // response.data.key is used to check if its not login request and locatstorage has email
//   const user =
//     !response.data.key && localStorage.getItem("email") && !userIdFetched
//       ? await getUserObj(params)
//       : "";
//   const userId = user.data ? user.data.results[0].pk : "";
//   userId && !permissionApiFired && user.data.results[0].permissions_updated
//     ? await editUserPermission({ id: userId, permissions_updated: false })
//     : "";
//   !response.data.key && userId && user.data.results[0].permissions_updated
//     ? await updatePermissionsForceLogout()
//     : "";
//   userIdFetched = false; // resetting to fire getUserObj id when logged in and group changes from backeden
// }

const scanApi = {
  getHouseTypes,
  createUser,
  getUser,
  updateUser,
  getHouseItems,
  getRecommendedItem,
  getItemUserQuestion,
  sendItemUserValues,
  sendUserQuote,
  getStreetInfo,
  sendItemUserSubsidy,
  getItemUserSubsidy,
  getConstructionYear,
  getLivingSpace,
  getCityQuestion,
  getDomainInfo,
  getLogoScanDomainInfo,
  loginUser,
  forgotPassword,
  createReview,
  // sendPermissionUpdated,
};

export default scanApi;
