/* eslint-disable */
import scanApi from "../apis/scan";

const uid = localStorage.getItem("uid");
const email = localStorage.getItem("email");
const userToken = localStorage.getItem("userToken");
// const user = localStorage.getItem("email");
// const permissions = JSON.parse(localStorage.getItem("permissions"));
if (localStorage.getItem("role")) {
  localStorage.clear();
  window.location.reload();
}

const initialState = {
  houseTypes: [],
  uid: uid,
  email: email,
  user: {},
  logoUser: {
    token: userToken,
    email: "",
    // permissions,
  },
  waterPumpValues:[],
  houseDetail: {
    streetName: "",
    district: "",
    houseNumber: "",
    postCode: "",
    construction_year_url: "",
    living_space_url: "",
    houseImage: "",
    apartment_number: "",
    house_letter: "",
  },
  status: "loading",
  facilityItems: "",
  recommendedItems: [],
  selectedEligibility: [],
  itemUserQuestion: [],
  itemUserValues: [],
  isValidHouseNo: true,
  isApiError: false,
  domainInfo: {},
  // logoScanInfo: {},

};

// const initialState = userToken 
// ? {
//   status: { loggedIn: true },
//   houseTypes: [],
//   uid: uid,
//   email: email,
//   // user: {},
//   user: {
//     token: userToken,
//     // email: user,
//     // permissions,
//   },
//   waterPumpValues:[],
//   houseDetail: {
//     streetName: "",
//     district: "",
//     houseNumber: "",
//     postCode: "",
//     construction_year_url: "",
//     living_space_url: "",
//     houseImage: "",
//     house_letter: "",
//   },
//   status: "loading",
//   facilityItems: "",
//   recommendedItems: [],
//   selectedEligibility: [],
//   itemUserQuestion: [],
//   itemUserValues: [],
//   isValidHouseNo: true,
//   isApiError: false,
//   domainInfo: {},
//   // logoScanInfo: {},

// }
// :{
//   status: { loggedIn: false },
//   houseTypes: [],
//   uid: uid,
//   email: email,
//   // user: {},
//   user: {
//     token: "",
//     // email: user,
//     // permissions,
//   },
//   waterPumpValues:[],
//   houseDetail: {
//     streetName: "",
//     district: "",
//     houseNumber: "",
//     postCode: "",
//     construction_year_url: "",
//     living_space_url: "",
//     houseImage: "",
//     house_letter: "",
//   },
//   status: "loading",
//   facilityItems: "",
//   recommendedItems: [],
//   selectedEligibility: [],
//   itemUserQuestion: [],
//   itemUserValues: [],
//   isValidHouseNo: true,
//   isApiError: false,
//   domainInfo: {},
//   // logoScanInfo: {},

// };

const actions = {
  getHouseTypes({ commit }) {
    return scanApi.getHouseTypes().then(
      (res) => {
        commit("setHouseTypes", res);
      },
      () => {
        console.log("Error !");
      }
    );
  },

  createUser({ commit }, reqBody) {
    return scanApi.createUser(reqBody).then(
      (res) => {
        commit("setUser", res);
        commit("setFailureStatus", false);
        return res;
      },
      (error) => {
        console.log("Error !");
        console.log('errrorrr',error);
        commit("setFailureStatus", true);
        return error;
      }
    );
  },
  updateUserAction({ commit }, reqBody) {
    const uuid = localStorage.getItem("uid");
    if(uuid) {
      return scanApi.updateUser(uuid, reqBody).then(
        (res) => {
            commit("setUser", res);
            commit("setFailureStatus", false);
            return {isSuccess: true};
          },
          () => {
            console.log("Error !");
            commit("setFailureStatus", true);
          }
      );
    }
  },
  getUser({ commit }) {
    commit("setUserStatus", "loading");
    const uuid = localStorage.getItem("uid");
    console.log('get local uid=>', uuid);
    if (uuid) {
      return scanApi.getUser(uuid).then(
        (res) => {
          commit("setUser", res);
          commit("setUserStatus", "success");
          commit("setFailureStatus", false);

          return res;
        },
        (error) => {
          console.log("Error !");
          commit("setUserStatus", "error");
          commit("setFailureStatus", true);
          return error;
        }
      );
    } else {
      commit("setUserStatus", "success");
      commit("setFailureStatus", true);
      return Promise.resolve(false);
    }
  },
  getItems({ commit }) {
    scanApi.getHouseItems().then(
      (res) => {
        commit("setHouseItems", res);
      },
      () => {
        console.log("Error !");
      }
    );
  },
  getDomainInfoAction({ commit }, queryParams) {
    return scanApi.getDomainInfo(queryParams).then(
      (res) => {
        commit("setDomainInfo", res);
        return res;
      },
      () => {
        console.log("Error !");
      }
    );
  },
  // getLogoScanDomainInfoAction({ commit }, queryParams) {
  //   return scanApi.getLogoScanDomainInfo(queryParams).then(
  //     (res) => {
  //       console.log(res.results.length?res.results[0]:{});
  //       // commit("setLogoScanDomainInfo", res);
  //       // return res;
  //     },
  //     () => {
  //       console.log("Error !");
  //     }
  //   );
  // },

  loginUserAction({ commit, dispatch }, reqData) {
    return scanApi.loginUser(reqData).then(
      (res) => {
        console.log(res)
        commit("setUser", res);
        // commit("setPermisions", res.res.data);
        // dispatch(
        //   "alert/success",
        //   `Hi ${res.res.data.user.email}, you are successfully logged in as ${res.res.data.user.group.name}`,
        //   { root: true }
        // );

        localStorage.setItem("logoScanEmail", res.email);
        localStorage.setItem("userToken", res.access);

        return {'isSuccess':true, 'user':res};
      },
      (err) => {
        // userService.logout();
        // commit("setLoginFailure");
        // if (!err.isSuccess && "non_field_errors" in err.res.data) {
        //   dispatch("alert/error", err.res.data.non_field_errors[0], {
        //     root: true,
        //   });
        // }
        return {'isSuccess':false, 'user':null};
      }
    );
  },

  forgotPasswordAction({ dispatch }, payload) {
    return scanApi.forgotPassword(payload).then(
      (res) => {
        // dispatch("alert/success", `Please check your email`, { root: true });
        return res;
      },
      (res) => {
        return res;
      }
    );
  },

  getItemRecommendation({ commit }, reqBody) {
    const uid = localStorage.getItem("uid");
    scanApi.getRecommendedItem(reqBody, uid).then(
      (res) => {
        commit("setRecommendedItem", res);
        commit("setFailureStatus", false);
      },
      (error) => {
        console.log("Error !");
        commit("setFailureStatus", true);
      }
    );
  },
  getItemUserQuestion({ commit }, reqBody) {
    scanApi.getItemUserQuestion(reqBody).then(
      (res) => {
        commit("setItemUserQuestion", res);
      },
      () => {
        console.log("Error !");
      }
    );
  },
  sendItemUserSubsidyAction({ commit }, reqBody) {
    const uid = localStorage.getItem("uid");
    scanApi.sendItemUserSubsidy(reqBody, uid).then(
      (res) => {
        // commit("setItemUserQuestion", res);
        console.log("%%%%", res);
      },
      () => {
        console.log("Error !");
      }
    );
  },
  getItemUserSubsidyAction({ commit }) {
    const uid = localStorage.getItem("uid");
    scanApi.getItemUserSubsidy(uid).then(
      (res) => {
        commit("setItemUserSubsidy", res.selected_items);
        console.log("%%%%", res);
      },
      () => {
        console.log("Error !");
      }
    );
  },
  sendItemUserValues({ commit }, reqBody) {
    scanApi.sendItemUserValues(reqBody).then(
      (res) => {
        commit("setsetItemUserValues", res);
      },
      () => {
        console.log("Error !");
      }
    );
  },
  sendUserQuoteAction({ commit }, queryParams) {
    const uuid = localStorage.getItem("uid");
    return scanApi.sendUserQuote(uuid, queryParams).then(
      (res) => {
        commit("setFailureStatus", false);
      },
      () => {
        console.log("Error !");
        commit("setFailureStatus", true);
      }
    );
  },
  getStreetInformation({ commit, dispatch, state }, reqData) {
    let url = "";
    reqData.house_letter
      ? (url = `https://api.bag.kadaster.nl/lvbag/individuelebevragingen/v2/adressen?postcode=${reqData.postcode}&huisnummer=${reqData.huisnummer}&huisletter=${reqData.house_letter}`)
      : (url = `https://api.bag.kadaster.nl/lvbag/individuelebevragingen/v2/adressen?postcode=${reqData.postcode}&huisnummer=${reqData.huisnummer}`);
    return scanApi.getStreetInfo(url).then(
      (res) => {
        commit("setStreetInformation", res);

     
        // TODO: Remove if not needed
        // dispatch("updateUserAction", {
        //   street_name: state.houseDetail.streetName`,
        //   house_image: state.houseDetail.houseImage,
        //   house_letter:state.houseDetail.house_letter,
        //   city: state.houseDetail.district
        // });

        return res;
      },
      () => {
        console.log("Error !");
      

        initialState.isValidHouseNo = true;
        // initialState.isValidHouseNo = "voer een geldig huisnummer of postcode in";
      }
    );
  },
  getLivingSpace({ commit, dispatch }) {
    const url = initialState.houseDetail["living_space_url"];
    return scanApi.getLivingSpace(url).then(
      (res) => {
        commit("setLivingSpace", res);
        dispatch("updateUserAction", {
          living_space: res["verblijfsobject"]["oppervlakte"]
            ? res["verblijfsobject"]["oppervlakte"]
            : "",
        });
      },
      () => {
        console.log("Error !");
        initialState.isValidHouseNo =
          "voer een geldig huisnummer of postcode in";
      }
    );
  },
  getConstructionYear({ commit, dispatch }) {
    const url = initialState.houseDetail["construction_year_url"];
    return scanApi.getConstructionYear(url).then(
      (res) => {
        commit("setConstructionYear", res);
        dispatch("updateUserAction", {
          construction_year: res["pand"]["oorspronkelijkBouwjaar"]
            ? res["pand"]["oorspronkelijkBouwjaar"]
            : false,
        });
      },
      () => {
        console.log("Error !");
        initialState.isValidHouseNo =
          "voer een geldig huisnummer of postcode in";
      }
    );
  },
  createReviewAction({ dispatch }, reqData) {
    console.log(reqData)
    return scanApi.createReview(reqData).then(
      (res) => {
        dispatch("alert/success", `Review  created Succesfully.`, {
          root: true,
        });
        return res;
      },
      (error) => {
        dispatch("alert/success", `Review  created Succesfully.`, {
          root: true,
        });
        return error;
      }
    );
  },
  async getCityQuestion({ commit, dispatch, state }, reqBody) {
    return scanApi.getCityQuestion(reqBody).then(
      (res) => {
        commit("setCityQuestion", res);
        // dispatch("updateUserAction", {
        //   city: state.houseDetail.city.name,
        // });
      },
      () => {
        console.log("Error !");
        initialState.houseDetail.city = null;
        initialState.houseDetail.cityQuestion = null;
        initialState.houseDetail.cityLink = null;
      }
    );
  },
};

const mutations = {
  setHouseTypes(state, houseTypes) {
    state.houseTypes = houseTypes;
  },
  setUser(state, user) {
    state.user = user;
    state.logoUser = user;
    // state.status.loggedIn = true,
      
    state.logoUser.token = user.access;
    state.logoUser.email = user.email

    state.uid = user.uid;
    state.email = user.email;

    if (user.city_name) {
      state.houseDetail.district = user.city_name;
    }
    if (user.city) {
      state.houseDetail.city = user.city;
    }
    if (user.street_name) {
      state.houseDetail.streetName = user.street_name;
    }
    if (user.house_number) {
      state.houseDetail.houseNumber = user.house_number;
    }
    if (user.apartment_number) {
      state.houseDetail.apartment_number = user.apartment_number;
    }
    if (user.house_letter) {
      state.houseDetail.house_letter = user.house_letter;
    }
    if (user.zipcode) {
      state.houseDetail.postCode = user.zipcode;
    }
    if (user.house_image) {
      state.houseDetail.houseImage = user.house_image;
    }
    if (user.special_item_values) {
      state.waterPumpValues = JSON.parse(user.special_item_values)
      state.waterPumpValues[7].value[0]['Hoeveel gas gebruikt u nu per jaar (m3)?'] = user.gas_usage && user.gas_usage !== 0?user.gas_usage: state.waterPumpValues[5].value[0]['Hoeveel gas gebruikt u nu per jaar (m3)?'];
    }
    else {
      state.waterPumpValues = [
      
        {
          question: "Welke warmtepomp heeft uw voorkeur?",
          type: "Dropdown",
          value: [
            {
              typeWarmtepomp: "",
            },
          ],
        },
        {
          question: "Wat is het type glas van de woning?",
          type: "radio",
          title: "Type of glass",
          value: "dubbel",
        },
        {
          question: "Wat zijn de eigenschappen van de woning?",
          type: "Dropdown",
          value: [
            {
              bodem: "",
            },
            {
              glas: "",
            },
            {
              ventilatieSysteem: "",
            },
            {
              hellenDak: "",
            },
          ],
        },
        {
          question: "Hoeveel bouwlagen heeft de woning? ",
          type: "Dropdown",
          value: [
            {
              "Antal bouwlagen": "bouwlagen2",
            },
          ],
        },
        {
          question: "Wat zijn de oppervlaktes van de bouwlagen?",
          type: "Dropdown",
          value: [
            {
              "Bouwlaag1": "",
            },
            {
              "Bouwlaag2": "",
            },
            { "Bouwlaag3": "" },
            { "Bouwlaag4": "" },
            { "Bouwlaag5": "" },
          ]
        },
        {
          question: "Wilt u ook het tapwatergebruik meenemen in de berekening?",
          type: "radio",
          title:
              "Het meenemen van het tapwaterverbruik zorgt voor een nauwkeurigere berekening​",
          value: "Nee, zonder tapwater berekenen",
        },
        {
          question: "Hoeveel gas gebruikt u nu per jaar (m3)?",
          type: "Dropdown",
          value: [
            {
              "Hoeveel gas gebruikt u nu per jaar (m3)?": user.gas_usage && user.gas_usage !== 0?user.gas_usage: ""
            },
          ],
        },
        {
          question: "Wat is de persoonlijke situatie van de woning?",
          type: "Dropdown",
          value: [
            {
              "Aantal inwoners": "",
            },
            {
              "Type douche": "",
            },
            {
              "Duur douche": "",
            },
          ],
        },
        // {
        //   question: "Welke warmtepomp heeft uw voorkeur?",
        //   type: "Dropdown",
        //   value: [
        //     {
        //       typeHeatPump: "",
        //     },
        //   ],
        // },
      ]
    }

    localStorage.setItem("email", user.email);
    localStorage.setItem("uid", user.uid);
  },
  setUserStatus(state, status) {
    state.status = status;
  },
  setHouseItems(state, items) {
    state.facilityItems = items;
  },
  setRecommendedItem(state, items) {
    state.recommendedItems = items;
  },
  setItemUserQuestion(state, items) {
    state.itemUserQuestion = items;
  },
  setsetItemUserValues(state, items) {
    state.setitemUserValues = items;
  },
  setItemUserSubsidy(state, items) {
    state.selectedEligibility = items;
  },
  setStreetInformation(state, items) {
    if (items["_embedded"] !== undefined) {
      state.isValidHouseNo = true;
      console.log(items);
      const street =
        items["_embedded"]["adressen"][0]["openbareRuimteNaam"].toString();
      const district =
        items["_embedded"]["adressen"][0]["woonplaatsNaam"].toString();
      const houseNumber =
        items["_embedded"]["adressen"][0]["huisnummer"].toString();
      const postCode = items["_embedded"]["adressen"][0]["postcode"].toString();
      const construction_year_url =
        items["_embedded"]["adressen"][0]["_links"]["panden"][0]["href"];
      const living_space_url =
        items["_embedded"]["adressen"][0]["_links"]["adresseerbaarObject"][
          "href"
        ];
      state.houseDetail.district
        ? (state.houseDetail.oldCity = state.houseDetail.district)
        : "";
      state.houseDetail = {
        streetName: street,
        district: district,
        houseNumber: houseNumber,
        postCode: postCode,
        construction_year_url: construction_year_url,
        living_space_url: living_space_url,
      };
      console.log(state.houseDetail)
      if (items["_embedded"]["adressen"][0]["huisletter"]) {
        const house_letter =
          items["_embedded"]["adressen"][0]["huisletter"].toString();
        state.houseDetail.house_letter = house_letter;
        const url = `https://maps.googleapis.com/maps/api/streetview?location=${encodeURIComponent(
          street
        )}%20${encodeURIComponent(
          houseNumber.concat(house_letter)
        )},%20${encodeURIComponent(
          postCode
        )}&size=800x400&key=${process.env.VUE_APP_GOOGLE_MAP_API_KEY}`;
        state.houseDetail.houseImage = url;
      } else {
        const url = `https://maps.googleapis.com/maps/api/streetview?location=${encodeURIComponent(
          street
        )}%20${encodeURIComponent(houseNumber)},%20${encodeURIComponent(
          postCode
        )}&size=800x400&key=${process.env.VUE_APP_GOOGLE_MAP_API_KEY}`;
        state.houseDetail.houseImage = url;
      }
      console.log('saved')
    } else {
      state.isValidHouseNo = true;
      // state.isValidHouseNo = "voer een geldig huisnummer of postcode in";
    }
  },
  setConstructionYear(state, res) {
    state.houseDetail.construction_year = res["pand"]["oorspronkelijkBouwjaar"]
      ? res["pand"]["oorspronkelijkBouwjaar"]
      : false;
  },
  setLivingSpace(state, res) {
    state.houseDetail.living_space = res["verblijfsobject"]["oppervlakte"]
      ? res["verblijfsobject"]["oppervlakte"]
      : false;
  },
  setCityQuestion(state, res) {
    state.houseDetail.cityQuestion = res.question;
    state.houseDetail.cityLink = res.link;
    state.houseDetail.city = res;
    state.houseDetail.district = res.name;
  },
  setFailureStatus(state, status) {
    initialState.isApiError = status;
  },
  setDomainInfo(state, res) {
    initialState.domainInfo = res.results.length?res.results[0]:{};
  },
  // setLogoScanDomainInfo(state, res) {
  //   console.log(res.results.length?res.results[0]:{});
  //   // initialState.lofoscandomainInfo = res.results.length?res.results[0]:{};
  // },
};

export const scan = {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
};
// export default scan
