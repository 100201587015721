import Vue from "vue";
import UUID from "vue-uuid";
import {InlineSvgPlugin} from 'vue-inline-svg';
import VueI18n from "vue-i18n";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

Vue.use(InlineSvgPlugin);
Vue.use(UUID);
Vue.use(VueI18n);
Vue.config.productionTip = false;

const numberFormats = {
  fr: {
    currency: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
    },
  },
};
const i18n = new VueI18n({
  numberFormats,
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
